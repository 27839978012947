import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Rocket, Lightbulb, Users, Target } from 'lucide-react';
import './CompanyInfo.css';
import { CarouselImgs, TeamImgs } from '../../tools/LoadImages';

const LazyImage = ({ src, alt }) => {
  const [imageSrc, setImageSrc] = React.useState('placeholder.jpg');

  React.useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageSrc(src);
    };
  }, [src]);

  return <img src={imageSrc} alt={alt} />;
};

const CompanyInfo = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentNewsPage, setCurrentNewsPage] = useState(0);

  const carouselItems = [
    { image: `${CarouselImgs["cr1"]}`, title: 'Innovative Solutions' },
    { image:  `${CarouselImgs["cr2"]}`, title: 'Cutting-edge Technology' },
    { image:  `${CarouselImgs["cr3"]}`, title: 'Expert Team' },
  ];

  const recentNews = [
    { title: 'Wisish Tech Launches New AI Platform', abstract: 'Our latest AI platform is set to revolutionize the industry with its advanced machine learning capabilities and intuitive user interface.' },
    { title: 'Our Team Wins Innovation Award', abstract: 'Wisish Tech\'s dedication to pushing the boundaries of technology has been recognized with a prestigious innovation award.' },
    { title: 'Expanding Our Services to New Markets', abstract: 'We\'re excited to announce our expansion into new markets, bringing our cutting-edge solutions to more businesses worldwide.' },
    { title: 'Partnering with Leading Tech Giants', abstract: 'Wisish Tech has formed strategic partnerships with industry leaders to deliver even more powerful and integrated solutions.' },
    { title: 'Introducing Our Latest Software Solution', abstract: 'Our newest software release promises to streamline workflows and boost productivity for businesses of all sizes.' },
    { title: 'Wisish Tech Hosts Annual Developer Conference', abstract: 'Join us for our biggest developer conference yet, featuring keynotes from industry experts and hands-on workshops.' },
    { title: 'New Office Opening in Silicon Valley', abstract: 'We\'re thrilled to announce the opening of our new Silicon Valley office, expanding our presence in the tech capital.' },
    { title: 'Achieving Record Growth in Q2', abstract: 'Wisish Tech reports unprecedented growth in Q2, thanks to our innovative products and dedicated team.' },
    { title: 'Committed to Sustainable Tech Practices', abstract: 'Learn about our initiatives to make our technology and practices more environmentally friendly and sustainable.' },
    { title: 'Welcoming New Talent to Our Team', abstract: 'We\'re expanding our team of experts! Meet the newest members bringing fresh perspectives to Wisish Tech.' },
  ];

  const approachCards = [
    { icon: <Rocket />, title: 'Agile Methodology', description: 'We embrace agile principles for flexible and efficient development.' },
    { icon: <Lightbulb />, title: 'User-Centered Design', description: 'Our designs prioritize user experience and intuitive interfaces.' },
    { icon: <Target />, title: 'Continuous Integration', description: 'We utilize CI/CD for seamless updates and deployments.' },
    { icon: <Users />, title: 'Quality Assurance', description: 'Rigorous testing ensures top-notch product quality.' },
  ];

  const newsPerPage = 3;
  const totalNewsPages = Math.ceil(recentNews.length / newsPerPage);

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + carouselItems.length) % carouselItems.length);

  const nextNewsPage = () => setCurrentNewsPage((prev) => (prev + 1) % totalNewsPages);
  const prevNewsPage = () => setCurrentNewsPage((prev) => (prev - 1 + totalNewsPages) % totalNewsPages);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Autoplay every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="company-info-container">
      <div className="carousel-news-section">
        <div className="carousel">
          {carouselItems.map((item, index) => (
            <div key={index} className={`carousel-item ${index === currentSlide ? 'active' : ''}`}>
              <LazyImage src={item.image} alt={item.title} />
              <h3>{item.title}</h3>
            </div>
          ))}
          <button className="carousel-control left" onClick={prevSlide}><ChevronLeft /></button>
          <button className="carousel-control right" onClick={nextSlide}><ChevronRight /></button>
          <div className="carousel-indicators">
            {carouselItems.map((_, index) => (
              <span key={index} className={`indicator ${index === currentSlide ? 'active' : ''}`} />
            ))}
          </div>
        </div>
        <div className="recent-news">
          <h2>Recent Articles</h2>
          <ul>
            {recentNews.slice(currentNewsPage * newsPerPage, (currentNewsPage + 1) * newsPerPage).map((news, index) => (
              <li key={index}>
                <a href="#" className="news-title">{news.title}</a>
                <p className="news-abstract">{news.abstract}</p>
              </li>
            ))}
          </ul>
          <div className="news-pagination">
            <button onClick={prevNewsPage}><ChevronLeft /></button>
            <span>{currentNewsPage + 1} / {totalNewsPages}</span>
            <button onClick={nextNewsPage}><ChevronRight /></button>
          </div>
        </div>
      </div>
      <div className="about-us">
        <div className="about-us-content">
          <h2>About Us</h2>
          <p>
            At Wisish Tech, we're not just another IT company. We're innovators, dreamers, and problem-solvers 
            passionate about leveraging cutting-edge technology to transform businesses and improve lives.
          </p>
          <p>
            Our mission is to empower organizations through innovative IT solutions that drive growth, 
            efficiency, and digital transformation. With a team of expert developers, designers, and 
            consultants, we're committed to pushing the boundaries of what's possible in the digital realm.
          </p>
        </div>
        <div className="about-us-image">
          <LazyImage src={`${TeamImgs["team"]}`} alt="Wisish Tech Team" />
        </div>
      </div>
      <div className="our-approach">
        <h2>Our Design and Development Approach</h2>
        <div className="approach-cards">
          {approachCards.map((card, index) => (
            <div key={index} className="approach-card">
              <div className="card-icon">{card.icon}</div>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;