
import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer/Footer";
import HomeBanner from "../components/HomeBanner/HomeBanner";
import CompanyInfo from "../components/CompanyInfo/CompanyInfo";

const Home = () => {
  return (
    <div>
         <Header activePage="Home" />
         {/* <h1>Hello World</h1> */}
          <HomeBanner/>
          <CompanyInfo/>
         <Footer/>
    </div>
  );
};

export default Home;
