import React, { useState, useEffect } from 'react';
import { Search, MessageCircle, User, Menu, X, ChevronDown } from 'lucide-react';
import './Header.css';
import { HeaderImgs } from '../../tools/LoadImages';
import { Link } from 'react-router-dom';

const Header = ({ activePage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
        setIsSearchOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const links = [
    { name: 'Home', to: '/' },
    { name: 'About Us', to: '/about' },
    { name: 'Services', to: '/services' },
    { name: 'Products', to: '/products' },
    { name: 'Resources', to: '#', sublinks: [
      { name: 'Career Center', to: '/resources/career-center' },
      { name: 'Tech Analysis', to: '/resources/tech-analysis' },
      { name: 'Publications', to: '/resources/publications' }
    ]},
    { name: 'Insights', to: '/insights' },
  ];

  const handleDropdownToggle = (linkName, event) => {
    event.preventDefault();
    setOpenDropdown(openDropdown === linkName ? null : linkName);
  };

  return (
    <header className="header">
      <div className="header-container">
        {isMobile && (
          <div className="mobile-left">
            <button className="icon-button mobile-menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Menu className="icon" />
            </button>
            <button className="icon-button mobile-search-button" onClick={() => setIsSearchOpen(true)}>
              <Search className="icon" />
            </button>
          </div>
        )}

        <div className="left-section">

          <div className="logo">
            
            <div className="logo-background">
              <Link to="/">
                  <img src={HeaderImgs["logo"]} alt="Logo" />
              </Link>
            </div>

          </div>

          {!isMobile && (
            <nav className="desktop-nav">
              {links.map((link) => (
                <div key={link.name} className="nav-item">
                  {link.sublinks ? (
                    <a
                      href={link.to}
                      className={`nav-link ${activePage === link.name ? 'active' : ''}`}
                      onClick={(e) => handleDropdownToggle(link.name, e)}
                    >
                      <span className="nav-link-text">{link.name}</span>
                      <ChevronDown className={`dropdown-arrow ${openDropdown === link.name ? 'open' : ''}`} />
                    </a>
                  ) : (
                    <Link
                      to={link.to}
                      className={`nav-link ${activePage === link.name ? 'active' : ''}`}
                    >
                      <span className="nav-link-text">{link.name}</span>
                    </Link>
                  )}
                  {link.sublinks && openDropdown === link.name && (
                    <div className="dropdown">
                      <div className="dropdown-header"></div>
                      {link.sublinks.map((sublink) => (
                        <Link 
                          key={sublink.name} 
                          to={sublink.to} 
                          className="dropdown-link"
                        >
                          {sublink.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          )}
        </div>

        <div className="right-section">
          {!isMobile && (
            <button className="icon-button search-toggle" onClick={() => setIsSearchOpen(!isSearchOpen)}>
              <Search className="icon" />
            </button>
          )}
          <Link to="/contact" className="icon-button">
            <MessageCircle className="icon" />
          </Link>
          <Link to="/signin" className="icon-button">
            <User className="icon" />
          </Link>
        </div>

        {isSearchOpen && (
          <div className={`search-container ${isMobile ? 'mobile' : ''}`}>
            <input type="text" placeholder="Search all of WisishTech" className="search-input" />
            <button className="search-submit">
              <Search className="icon" />
            </button>
            <button className="search-close" onClick={() => setIsSearchOpen(false)}>
              <X className="icon" />
            </button>
          </div>
        )}
      </div>

      {isMobile && isMenuOpen && (
        <nav className="mobile-nav">
          {links.map((link) => (
            <div key={link.name} className="mobile-nav-item">
              {link.sublinks ? (
                <a
                  href={link.to}
                  className={`mobile-nav-link ${activePage === link.name ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsResourcesOpen(!isResourcesOpen);
                  }}
                >
                  <span className="nav-link-text">{link.name}</span>
                  <ChevronDown 
                    className={`dropdown-arrow ${isResourcesOpen ? 'open' : ''}`} 
                  />
                </a>
              ) : (
                <Link
                  to={link.to}
                  className={`mobile-nav-link ${activePage === link.name ? 'active' : ''}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="nav-link-text">{link.name}</span>
                </Link>
              )}

              {link.sublinks && isResourcesOpen && (
                <div className="mobile-dropdown">
                  {link.sublinks.map((sublink) => (
                    <Link
                      key={sublink.name}
                      to={sublink.to}
                      className="mobile-dropdown-link"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {sublink.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      )}
    </header>
  );
};

export default Header;