import React from 'react';
import { Twitter, Instagram, Linkedin, Youtube, ArrowUpRight } from 'lucide-react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { title: 'Company', links: ['About Us', 'Careers', 'Press', 'Insights'], redirect: ['about', '', '', 'insight'] },
    { title: 'Products', links: ['Solutions', 'Technology', 'Pricing', 'Documentation'], redirect: ['products', 'products', 'products', 'products'] },
    { title: 'Resources', links: ['Developer API', 'Partners', 'Community', 'Education'], redirect: ['services', 'services', 'services', 'services'] },
    { title: 'Connect', links: ['Contact Us', 'Support', 'Newsletter', 'Events'], redirect: ['contact', '', '', ''] },
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-main">
          <div className="footer-brand">
            <h2 className="footer-logo">WisishTech</h2>
            <p className="footer-slogan">Innovating for a smarter tomorrow</p>
            <div className="footer-social">
              <a href="https://x.com/wisishtech" className="social-icon"><Twitter /></a>
              <a href="https://instagram.com/wisishtech" className="social-icon"><Instagram /></a>
              <a href="https://www.linkedin.com/company/wisish-tech/" className="social-icon"><Linkedin /></a>
              <a href="https://youtube.com/@wisishtech" className="social-icon"><Youtube /></a>
            </div>
          </div>
          <div className="footer-links-grid">
          {footerLinks.map((column, index) => (
              <div key={index} className="footer-links-column">
                <h3 className="footer-links-title">{column.title}</h3>
                <ul className="footer-links-list">
                  {column.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a 
                        href={column.redirect[linkIndex] ? `/${column.redirect[linkIndex]}` : '#'} 
                        className="footer-link"
                      >
                        {link}
                        <ArrowUpRight className="link-arrow" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
          ))}
          </div>
        </div>
        <div className="footer-bottom">
          <p className="footer-copyright">
            © {currentYear} WisishTech. All rights reserved.
          </p>
          <div className="footer-legal-links">
            <a href="#" className="legal-link">Terms of Service</a>
            <a href="/privacy" className="legal-link">Privacy Policy</a>
            <a href="#" className="legal-link">Cookie Settings</a>
          </div>
        </div>
      </div>
      <div className="footer-background-pattern"></div>
    </footer>
  );
};

export default Footer;