import React, { useEffect, useRef, useState } from 'react';
import './AboutUs.css';
import { TechIcons, ValueImgs } from '../../tools/LoadImages';
import { motion, AnimatePresence } from 'framer-motion';

const AboutUs = () => {
  const canvasRef = useRef(null);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    for (let i = 0; i < 100; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 2,
        dy: (Math.random() - 0.5) * 2
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(236, 72, 153, 0.5)';
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();

        particle.x += particle.dx;
        particle.y += particle.dy;

        if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;
      });

      animationFrameId = requestAnimationFrame(drawParticles);
    };

    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const nextSlide = () => {
    setDirection(1);
    setCurrentSlide((prevSlide) => (prevSlide + 1) % 3);
  };

  const prevSlide = () => {
    setDirection(-1);
    setCurrentSlide((prevSlide) => (prevSlide - 1 + 3) % 3);
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
  };

  const sliderContent = [
    {
      title: "AI Ecosystem",
      description: "Revolutionizing businesses with advanced AI solutions, enabling smarter decision-making and efficient processes for a sustainable future.",
      icon: "🧠"
    },
    {
      title: "Quantum Computing",
      description: "Unlocking unprecedented computational power to solve complex global challenges in climate, healthcare, and resource management.",
      icon: "⚛️"
    },
    {
      title: "Cyber Security",
      description: "Next-gen protection for your digital assets, ensuring a safer digital world for businesses and individuals alike.",
      icon: "🛡️"
    }
  ];

  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <section className="intro-section">
          <canvas ref={canvasRef} className="particle-background"></canvas>
          <div className="intro-content">
            <h1 className="company-name">Wisish Tech</h1>
            <div className="tagline-container">
              <h2 className="tagline">Pioneering the Digital Frontier</h2>
              <p className="motto">Providing IT services and products for a better world</p>
              <div className="tagline-underline"></div>
            </div>
            <p className="intro-text">
              We don't just adapt to the future - we create it. Our team of visionaries and tech enthusiasts are
              committed to pushing the boundaries of innovation, crafting solutions that transform industries and enhance lives globally.
            </p>
            <div className="tech-icons">
              <img src={`${TechIcons["tech1"]}`} alt="AI" className="tech-icon" />
              <img src={`${TechIcons["tech2"]}`}  alt="Quantum Computing" className="tech-icon" />
              <img src={`${TechIcons["tech1"]}`}  alt="Cybersecurity" className="tech-icon" />
              <img src={`${TechIcons["tech3"]}`}  alt="Cloud Computing" className="tech-icon" />
            </div>
          </div>
        </section>

        <section className="mission-vision-section">
          <div className="mission">
            <h3>Our Mission</h3>
            <p>To harness cutting-edge technology in creating innovative IT services and products that drive positive change across industries and communities worldwide, delivering solutions that meet evolving business needs and contribute to a more efficient, sustainable, and connected global ecosystem.</p>
          </div>
          <div className="vision">
            <h3>Our Vision</h3>
            <p>A world where technology serves as a catalyst for positive transformation, bridging gaps and empowering individuals and organizations to reach their full potential. We aim to be at the forefront of this digital revolution, pioneering IT solutions that shape a better, more inclusive future for all.</p>
          </div>
        </section>

        <section className="core-values-section">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <div className="value-icon">
                <img src={`${ValueImgs["value1"]}`} alt="Innovation icon" className="tech-icon" />
              </div>
              <h3>Innovation</h3>
              <p>Pushing boundaries and redefining possibilities in tech.</p>
            </div>
            <div className="value-card">
              <div className="value-icon">
                <img src={`${ValueImgs["value2"]}`} alt="Integrity icon" className="tech-icon" />
              </div>
              <h3>Integrity</h3>
              <p>Building trust through transparency and ethical practices.</p>
            </div>
            <div className="value-card">
              <div className="value-icon">
                <img src={`${ValueImgs["value3"]}`} alt="Impact icon" className="tech-icon" />
              </div>
              <h3>Impact</h3>
              <p>Creating technology that makes a difference globally.</p>
            </div>
            <div className="value-card">
              <div className="value-icon">
                <img src={`${ValueImgs["value4"]}`} alt="Global Impact icon" className="tech-icon" />
              </div>
              <h3>Global Betterment</h3>
              <p>Leveraging technology to create positive change worldwide.</p>
            </div>
          </div>
        </section>

        <section className="solutions-section">
          <h2>Our Cutting-Edge Solutions</h2>
          <div className="solutions-slider">
            <button onClick={prevSlide} className="slider-button prev">&lt;</button>
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={currentSlide}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 }
                }}
                className="solution-slide"
              >
                <div className='slide-content'>
                  <div className="slide-icon">{sliderContent[currentSlide].icon}</div>
                  <h3>{sliderContent[currentSlide].title}</h3>
                  <p>{sliderContent[currentSlide].description}</p>
                </div>
              </motion.div>
            </AnimatePresence>
            <button onClick={nextSlide} className="slider-button next">&gt;</button>
          </div>
        </section>

        <section className="impact-section">
          <h2>Our Impact on the World</h2>
          <div className="impact-grid">
            <div className="impact-card">
              <h3>Sustainable Smart Cities</h3>
              <p>Our IoT solutions have helped reduce energy consumption by 30% in partner cities, promoting sustainable urban living.</p>
            </div>
            <div className="impact-card">
              <h3>Healthcare Revolution</h3>
              <p>Our AI-driven diagnostic tools have improved early disease detection rates by 45%, making healthcare more accessible and efficient.</p>
            </div>
            <div className="impact-card">
              <h3>Educational Empowerment</h3>
              <p>Our e-learning platforms have brought quality education to over 1 million students in underserved communities worldwide.</p>
            </div>
          </div>
        </section>

        <section className="tech-stack-section">
          <h2>Our Tech Stack</h2>
          <div className="tech-accordion">
            {['Frontend', 'Backend', 'DevOps', 'AI & Machine Learning'].map((tech, index) => (
              <div key={tech} className={`accordion-item ${activeAccordion === index ? 'active' : ''}`}>
                <button className="accordion-header" onClick={() => toggleAccordion(index)}>
                  {tech}
                </button>
                <div className="accordion-content">
                  <p>{`Our ${tech} stack includes cutting-edge technologies that enable us to build robust, scalable solutions for a better world.`}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="cta-section">
          <h2>Join Our Innovation Journey</h2>
          <p>Be part of a team that's shaping the future of technology and making a positive impact on the world.</p>
          <button className="cta-button">Explore Opportunities</button>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;