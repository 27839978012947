
import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer/Footer";

function Services() {
  return (
    <div>    
        <Header activePage="Services" />
            <div style={{height: "50vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <h1>Coming Soon</h1>
            </div>
        <Footer/>
    </div>
  )
}

export default Services