import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Services from "./pages/Services";
import Insights from "./pages/Insights";
import NotFound from './pages/NotFound'; // Create this component



function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home | Wisish Tech";
        metaDescription = "Welcome to Wisish Tech. We provide IT services and products for a better world.";
        break;
      case "/products":
        title = "Products | Wisish Tech";
        metaDescription = "Explore Wisish Tech's innovative products...";
        break;
      case "/about":
        title = "About Us | Wisish Tech";
        metaDescription = "Learn about Wisish Tech and our mission...";
        break;
      case "/services":
        title = "Services | Wisish Tech";
        metaDescription = "Discover the range of services offered by Wisish Tech...";
        break;
      case "/privacy":
        title = "Privacy Policy | Wisish Tech";
        metaDescription = "Read Wisish Tech's privacy policy...";
        break;
      case "/insights":
        title = "Insights | Wisish Tech";
        metaDescription = "Gain valuable insights from Wisish Tech...";
        break;
      default:
        title = "Page Not Found | Wisish Tech";
        metaDescription = "The page you're looking for doesn't exist. Please check the URL or navigate back to the homepage.";
    }
    
    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/insights" element={<Insights />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/insights" element={<Insights />} />
      <Route path="*" element={<NotFound />} /> {/* This is the 404 route */}
    </Routes>
  );
}
export default App;
