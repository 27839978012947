import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer/Footer";
import AboutUs from "../components/AboutUs/AboutUs";


const About = () => {
  return (
    <div>
        <Header activePage="About Us" />
        <AboutUs/>
        <Footer/>

    </div>
  );
};

export default About;
