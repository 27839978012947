import logo from "../assets/images/wisishtech-logo.png";
import Banner1 from "../assets/images/Banner.jpg";
import Banner2 from "../assets/images/Banner2.jpg";
import Banner3 from "../assets/images/Banner3.jpg";
import Banner4 from "../assets/images/Banner4.jpg";
import Banner5 from "../assets/images/Banner5.jpg";
import cr1 from "../assets/images/cr1.jpg";
import cr2 from "../assets/images/cr2.jpg";
import cr3 from "../assets/images/cr3.jpg";
import tech1 from "../assets/images/ai.svg";
import tech2 from "../assets/images/tech.png";
import tech3 from "../assets/images/code.png";
import value1 from "../assets/images/inv.png";
import value2 from "../assets/images/int.png";
import value3 from "../assets/images/imp.png";
import value4 from "../assets/images/glob.png"; 
import team from "../assets/images/team.png";






export const HeaderImgs = {
    "logo": logo
}

export const BannerImgs = {
    "banner1": Banner1,
    "banner2": Banner2,
    "banner3": Banner3,
    "banner4": Banner4,
    "banner5": Banner5
}

export const CarouselImgs = {
    "cr1": cr1,
    "cr2": cr2,
    "cr3": cr3,
}

export const TechIcons = {
    "tech1": tech1,
    "tech2": tech2,
    "tech3": tech3,
}

export const ValueImgs = {
    "value1": value1,
    "value2": value2,
    "value3": value3,
    "value4": value4,
}

export const TeamImgs =  {
    "team": team,
}