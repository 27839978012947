import React, { useState, useEffect, useCallback } from 'react';
import { Code, Globe, Smartphone, Gamepad, Database, Cpu, ShieldCheck, BarChart2, Video } from 'lucide-react';
import './HomeBanner.css';
import { BannerImgs } from '../../tools/LoadImages';

const HomeBanner = () => {
  const [activeCell, setActiveCell] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

  const images = [
    `${BannerImgs["banner1"]}`,
    `${BannerImgs["banner2"]}`,
    `${BannerImgs["banner3"]}`,
    `${BannerImgs["banner4"]}`,
    `${BannerImgs["banner5"]}`,
  ];

  const services = [
    { icon: <Code />, name: 'Software Dev' },
    { icon: <Globe />, name: 'Web Dev' },
    { icon: <Smartphone />, name: 'Mobile Dev' },
    { icon: <Gamepad />, name: 'Gaming Dev' },
    { icon: <Database />, name: 'Blockchain Dev' },
    { icon: <Cpu />, name: 'Fintech' },
    { icon: <ShieldCheck />, name: 'Security' },
    { icon: <BarChart2 />, name: 'Data Science' },
    { icon: <Video />, name: 'Media' },
  ];

  const nextService = useCallback(() => {
    setCurrentServiceIndex((prevIndex) => (prevIndex + 1) % services.length);
  }, [services.length]);

  useEffect(() => {
    const cellInterval = setInterval(() => {
      setActiveCell(Math.floor(Math.random() * 100));
    }, 2000);

    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    const serviceInterval = setInterval(nextService, 3000);

    return () => {
      clearInterval(cellInterval);
      clearInterval(imageInterval);
      clearInterval(serviceInterval);
    };
  }, [nextService, images.length]);

  const getVisibleServices = () => {
    const visibleServices = [];
    for (let i = 0; i < 3; i++) {
      const index = (currentServiceIndex + i) % services.length;
      visibleServices.push(services[index]);
    }
    return visibleServices;
  };

  return (
    <div className="home-banner-container">
      <div className="image-slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={`banner-image ${index === currentImageIndex ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>
      <div className="grid-overlay">
        {[...Array(100)].map((_, index) => (
          <div
            key={index}
            className={`grid-cell ${index === activeCell ? 'active' : ''}`}
          />
        ))}
      </div>
      <div className="banner-content">
        <h1 className="banner-title">
          Creating IT Services and Products for a Better World
        </h1>
        <p className="banner-subtitle">Innovative solutions to empower your business.</p>
        <p className="banner-slogan">Innovate. Transform. Lead.</p>
        <p className="banner-description">
          Cutting-edge solutions for the digital age.
        </p>
        <button className="cta-button">Explore Our Services</button>
      </div>
      <div className="services-carousel">
        <div className="services-container">
          {getVisibleServices().map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-icon">{service.icon}</div>
              <div className="service-name">{service.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;